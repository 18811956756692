import { httpService } from 'core-utilities';
import apiConstants from '../constants/apiEndpoint';

const getCanMessage = async (profileId: number): Promise<{ canMessage: boolean }> => {
  const { data }: { data: { canMessage: boolean } } = await httpService.get(
    apiConstants.getCanMessage(profileId)
  );
  return data;
};

export default {
  getCanMessage
};
