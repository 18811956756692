import { httpService } from 'core-utilities';
import apiConstants from '../constants/apiEndpoint';
import {
  TGetPrivateMessagesUIPolicyResponse,
  TGetWebProfileUIPolicyResponse
} from '../types/profileHeaderTypes';

const getPolicies = async (): Promise<TGetWebProfileUIPolicyResponse> => {
  const { data }: { data: TGetWebProfileUIPolicyResponse } = await httpService.get(
    apiConstants.webProfileUIPolicy()
  );
  return data;
};

const getPrivateMessagesPolicies = async (): Promise<TGetPrivateMessagesUIPolicyResponse> => {
  const { data }: { data: TGetPrivateMessagesUIPolicyResponse } = await httpService.get(
    apiConstants.privateMessagesUIPolicy()
  );
  return data;
};

export default {
  getPolicies,
  getPrivateMessagesPolicies
};
